import { ErrorMessage } from "~/components/fieldset.tsx";

export function ValidationError({
    name,
    errors,
}: {
    name: string;
    errors?: { [key: string]: string[] };
}) {
    return (
        <>
            {errors && Object.prototype.hasOwnProperty.call(errors, name) && (
                <ErrorMessage>{errors[name][0]}</ErrorMessage>
            )}
        </>
    );
}
